<template>
  <div>
    <v-card
      class="mx-auto"
      width="250"
      style="min-height: 100% !important"
      elevation="20"
      color="#ffffff"
    >
      <div class="dot-online" title="Working"></div>
      <img
        v-if="
          selectedCompany.user_status == 'employee' &&
          !selectedCompanyUserHasTeam
        "
        :src="$api_url + 'storage/' + member.image"
        @error="
          $event.target.src = require('../../assets/images/no-member.svg')
        "
        class="img-fluid proj-img mt-2"
        height="70"
        v-bind="attrs"
        v-on="on"
      />
      <img
        v-else
        :src="$api_url + 'storage/' + member.image"
        @error="
          $event.target.src = require('../../assets/images/no-member.svg')
        "
        height="70"
        alt="Thumbnail"
        class="img-fluid proj-img mt-2"
        style="
          border-radius: 100%;
          max-width: 65px;
          max-height: 65px;
          border: none;
        "
      />
      <div>
        <h3 class="text-center">{{ member.name ? member.name : "" }}</h3>
        <span class="text-center my-label-2">{{
          member.email ? member.email : ""
        }}</span>
      </div>
      <v-card-text>
        <v-col class="d-flex flex-column mb-2">
          <span
            v-if="member.designation"
            class="text-left ml-2 text-uppercase my-label"
            >Designation :
          </span>
          <v-chip
            small
            label
            pill
            color="#2758f6"
            class="ma-2 mt-n1"
            outlined
            v-if="member.designation"
          >
            {{ member.designation ? member.designation : "Not Assign" }}
          </v-chip>
          <span
            v-if="member.department_name"
            class="text-left ml-2 text-uppercase my-label"
            >Department :
          </span>
          <v-chip
            small
            label
            pill
            color="#2758f6"
            class="ma-2 mt-n1"
            outlined
            v-if="member.department_name"
          >
            {{ member.department_name ? member.department_name : "Not Assign" }}
          </v-chip>
          <span class="text-left ml-2 text-uppercase my-label">Role : </span>
          <v-chip
            small
            label
            color="#2758f6"
            outlined
            class="ma-2 mt-n1"
            pill
            v-if="
              member.user_role ||
              member.is_secondary_user ||
              member.super_secure_user ||
              member.team_lead_members
            "
          >
            {{
              member.user_role
                ? member.user_role
                : member.is_secondary_user
                ? "Admin"
                : member.super_secure_user
                ? "Super Secure User"
                : member.team_lead_members
                ? "Team Lead"
                : "Team Member"
            }}
          </v-chip>
          <v-chip
            small
            label
            pill
            class="ma-2 mt-n1"
            color="#2758f6"
            outlined
            v-else
          >
            Team Member
          </v-chip>
          <span
            v-if="member.silent_user"
            class="text-left ml-2 text-uppercase my-label"
            >Type :
          </span>
          <v-chip
            small
            label
            pill
            class="ma-2 mt-n1"
            color="#2758f6"
            outlined
            v-if="member.silent_user"
          >
            Silent User
          </v-chip>
        </v-col>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";

export default {
  name: "UserInfo",
  props: ["member"],
  data() {
    return {};
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("custom", ["companies_list", "team_members"]),
    url() {
      return baseURL.API_URL;
    },
    selectedCompany() {
      let company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      return company;
    },
    selectedCompanyUserHasTeam() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.has_team;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
  },
};
</script>


<style scoped>
.my-label {
  font-size: xx-small;
}
.my-label-2 {
  font-size: small;
}
</style>

