<template>
  <div class="col-md-11 indentText">
    <div class="row row1 mt-0">
      <div @click="isEnabledMethod" class="hand">
        <span class="pull-left" style="cursor: pointer">
          <!--<i class="fa fa-minus-circle" v-if="isenabled"></i>-->
          <img
            class=""
            src="../../../assets/images/Down.svg"
            v-if="isEnabled"
          />
          <img class="" src="../../../assets/images/Up.svg" v-else />
          <!--<i class="fa fa-plus-circle"></i>-->
        </span>
        <div>
          <v-tooltip class="uner_info_tooltip" top color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <sub v-if="member.img !== ''">
                <img
                  v-if="displayTempImg"
                  src="../../../assets/images/no-member.svg"
                  @error="
                    $event.target.src = require('../../../assets/images/no-member.svg')
                  "
                  alt="no member"
                  class="report-image"
                  v-bind="attrs"
                  v-on="on"
                />
                <img
                  v-else
                  :src="`${url()}storage/${member.image}`"
                  @error="
                    $event.target.src = require('../../../assets/images/no-member.svg')
                  "
                  alt="Thumbnail"
                  class="report-image"
                  v-bind="attrs"
                  v-on="on"
                />
              </sub>
              <sub v-else>
                <img
                  class="report-member-image"
                  src="../../../assets/images/no-member.svg"
                />
              </sub>
            </template>
            <span><UserInfo :member="userInfo(id)"></UserInfo></span>
          </v-tooltip>
          <h3 :title="member.name" class="name" style="display: inline-flex">
            {{ member.name }}
          </h3>
        </div>
      </div>
      <div class="time-div col2">
        <h3 class="pull-right" title="Hours:Minutes">
          {{ min_to_hour(member.member_total_time) }}
        </h3>
      </div>
    </div>
    <div class="row" v-if="isEnabled">
      <date
        :date="date"
        :date2="index"
        :key="index"
        v-for="(date, index) in member.dates"
      />
    </div>
  </div>
</template>
<script>
import { min_to_hour } from "@/assets/js/utils";
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";
import UserInfo from "../../includes/UserInfo";
export default {
  name: "members",
  components: {
    date: () => import("../../../components/reports/reportPage/date"),
    UserInfo,
  },
  data() {
    return {
      displayTempImg: true,
      isEnabled: true,
    };
  },
  computed: {
    ...mapState("custom", ["lead_team_members", "team_members"]),
  },
  mounted() {
    setTimeout(() => {
      this.displayTempImg = false;
    }, 5000);
    this.$root.$on("reset_report_collapse", () => {
      this.isEnabled = true;
    });
  },
  props: ["member", "id", "projectId"],
  methods: {
    userInfo(id) {
      if (this.lead_team_members.length > 0) {
        let member = this.lead_team_members.find((data) => data.id == id);
        return member ? member : [];
      } else {
        let member = this.$store.state.custom.get_team_members_list.find(
          (data) => data.id == id
        );
        return member ? member : [];
      }
    },
    url() {
      return baseURL.API_URL;
    },
    isEnabledMethod() {
      this.isEnabled = !this.isEnabled;
    },
    min_to_hour(minutes) {
      let time = min_to_hour(minutes);
      let hrs = time.hrs;
      let mins = time.mins;
      if (hrs <= 9) {
        hrs = "0" + time.hrs;
      }
      if (mins <= 9) {
        mins = "0" + time.mins;
      }
      return hrs + ":" + mins;
    },
  },
};
</script>

<style scoped>
.row1 {
  border: 0px;
  padding-right: 0px !important;

  white-space: nowrap;
  border-bottom: 1px solid #d6e7ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col2 {
  padding-right: 0px !important;
}

.time-div {
  padding: 15px;
  padding-left: 0px !important;
}

h3 {
  padding-top: 4px;
  margin-bottom: 0px;
  font-size: medium;
  font-weight: 400;
}

.hand {
  text-align: left;
  width: 78%;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  display: inherit;
  /* Non-prefixed version, currently
                                        supported by Chrome and Opera */
}

.hand h3 {
  white-space: pre-wrap;
}

.hand span {
  padding-right: 7px;
  padding-top: 1px;
}

.hand span i {
  font-size: large;
}

img.img-icon {
  width: 15px;
  height: 15px;
}

.col-md-6.hand {
  text-align: left;
}

.col-md-11.indentText {
  max-width: 97%;
  flex: 97%;
}

.indentText {
  margin-left: 3%;
}

@media (max-width: 367px) {
  .row1 h3 {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .time-div::before {
    content: "";
    border-right: 1px solid #d6e7ff;
    padding: 13px 0px;
    right: 50px;
    top: 15px;
    position: absolute;
  }
}

@media (max-width: 320px) {
  .time-div::before {
    right: 39px;
  }
}

.report-member-image {
  max-width: 45px;
  position: relative;
  margin-top: -15px;
}

.report-image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: relative;
  margin-top: -13px;
}

.name {
  position: relative;
  /*margin-top : -33px;*/
  /*left       : 6%;*/
  left: 14% !important;
  bottom: 20%;
}

@media (max-width: 2560px) {
  .name {
    left: 4%;
  }
}

@media (max-width: 1700px) {
  .name {
    left: 6%;
  }
}

@media (max-width: 1300px) {
  .name {
    left: 7%;
  }
}

@media (max-width: 1024px) {
  .name {
    left: 9%;
  }
}

@media (max-width: 768px) {
  .name {
    left: 12%;
  }
}

@media (max-width: 767px) {
  .name {
    width: 70px;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    left: 40% !important;
  }
}

@media (max-width: 425px) {
  .name {
    width: 70px;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    left: 25% !important;
  }
}

@media (max-width: 375px) {
  .name {
    width: 70px;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    left: 30% !important;
  }
}

@media (max-width: 320px) {
  .name {
    width: 70px;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    left: 34% !important;
  }
}

.zoom-image {
  border-radius: 3px;
}
</style>
