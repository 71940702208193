var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"mx-auto",staticStyle:{"min-height":"100% !important"},attrs:{"width":"250","elevation":"20","color":"#ffffff"}},[_c('div',{staticClass:"dot-online",attrs:{"title":"Working"}}),(
        _vm.selectedCompany.user_status == 'employee' &&
        !_vm.selectedCompanyUserHasTeam
      )?_c('img',_vm._g(_vm._b({staticClass:"img-fluid proj-img mt-2",attrs:{"src":_vm.$api_url + 'storage/' + _vm.member.image,"height":"70"},on:{"error":function($event){$event.target.src = require('../../assets/images/no-member.svg')}}},'img',_vm.attrs,false),_vm.on)):_c('img',{staticClass:"img-fluid proj-img mt-2",staticStyle:{"border-radius":"100%","max-width":"65px","max-height":"65px","border":"none"},attrs:{"src":_vm.$api_url + 'storage/' + _vm.member.image,"height":"70","alt":"Thumbnail"},on:{"error":function($event){$event.target.src = require('../../assets/images/no-member.svg')}}}),_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.member.name ? _vm.member.name : ""))]),_c('span',{staticClass:"text-center my-label-2"},[_vm._v(_vm._s(_vm.member.email ? _vm.member.email : ""))])]),_c('v-card-text',[_c('v-col',{staticClass:"d-flex flex-column mb-2"},[(_vm.member.designation)?_c('span',{staticClass:"text-left ml-2 text-uppercase my-label"},[_vm._v("Designation : ")]):_vm._e(),(_vm.member.designation)?_c('v-chip',{staticClass:"ma-2 mt-n1",attrs:{"small":"","label":"","pill":"","color":"#2758f6","outlined":""}},[_vm._v(" "+_vm._s(_vm.member.designation ? _vm.member.designation : "Not Assign")+" ")]):_vm._e(),(_vm.member.department_name)?_c('span',{staticClass:"text-left ml-2 text-uppercase my-label"},[_vm._v("Department : ")]):_vm._e(),(_vm.member.department_name)?_c('v-chip',{staticClass:"ma-2 mt-n1",attrs:{"small":"","label":"","pill":"","color":"#2758f6","outlined":""}},[_vm._v(" "+_vm._s(_vm.member.department_name ? _vm.member.department_name : "Not Assign")+" ")]):_vm._e(),_c('span',{staticClass:"text-left ml-2 text-uppercase my-label"},[_vm._v("Role : ")]),(
            _vm.member.user_role ||
            _vm.member.is_secondary_user ||
            _vm.member.super_secure_user ||
            _vm.member.team_lead_members
          )?_c('v-chip',{staticClass:"ma-2 mt-n1",attrs:{"small":"","label":"","color":"#2758f6","outlined":"","pill":""}},[_vm._v(" "+_vm._s(_vm.member.user_role ? _vm.member.user_role : _vm.member.is_secondary_user ? "Admin" : _vm.member.super_secure_user ? "Super Secure User" : _vm.member.team_lead_members ? "Team Lead" : "Team Member")+" ")]):_c('v-chip',{staticClass:"ma-2 mt-n1",attrs:{"small":"","label":"","pill":"","color":"#2758f6","outlined":""}},[_vm._v(" Team Member ")]),(_vm.member.silent_user)?_c('span',{staticClass:"text-left ml-2 text-uppercase my-label"},[_vm._v("Type : ")]):_vm._e(),(_vm.member.silent_user)?_c('v-chip',{staticClass:"ma-2 mt-n1",attrs:{"small":"","label":"","pill":"","color":"#2758f6","outlined":""}},[_vm._v(" Silent User ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }